@import "../vars.scss";

.welcome-screen {
    padding: 20px;
    background: linear-gradient(51deg, rgba(199,0,217,1) 0%, rgba(0,139,217,1) 100%);
    
    main {
        max-width: 700px;
    }

    .welcome-header {
        text-align: center;
    }

    .header-1 {
        font-size: 3rem;
        font-weight: 100;
        font-style: italic;
    }

    .header-2 {
        font-size: 3rem;
        font-weight: 700;
        margin: 0 0 40px 0;
    }

    .header-3 {
        font-size: 3rem;
        font-weight: 700;
        margin: 40px 0;
    }

    .welcome-posts {
        position: relative;
        height: 460px;
    }

    .welcome-post {
        width: 100%;
        max-width: 500px;
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        border-radius: 15px;
    }

    .post1 { 
        position: absolute;
        top: 0;
        left: 60px;
        transform: rotate(1deg);
    }

    .post2 {
        position: absolute;
        top: 120px;
        right: 20px;
        transform: rotate(-1deg);
    }

    .post3 {
        position: absolute;
        top: 300px;
        left: 65px;
        transform: rotate(1deg);
    }

    .welcome-content {
        background-color: rgba(35, 37, 45, 0.8);
        padding: 20px;
        border-radius: var(--border-radius);
    }

    .welcome-emoji {
        margin-right: 5px;
    }

    .auth-buttons {
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: center;
    }
}

@media #{$mobileMediaQuery} {
    .welcome-screen {
        padding-top: 30px;
        
        .header-1 {
            font-size: 2.5rem;
        }

        .header-2 {
            font-size: 2rem;
        }

        .header-3 {
            font-size: 2rem;
        }

        .welcome-posts {
            height: 340px;
            max-width: 400px;
            margin: 0 auto;
        }

        .welcome-post {
            max-width: 500px;
        }

        .post1 {
            left: auto;
            right: 3px;
        }

        .post2 {
            top: 100px;
            left: 5px;
            right: auto;
        }

        .post3 {
            top: 220px;
            left: auto;
            right: 3px;
        }

        .auth-buttons {
            flex-direction: column;
        }
    }
}