@import '../vars.scss';

.component-feed {
    display: flex;
    // align-items: stretch;
    align-items: center;
    width: 100%;

    .feed-item {
        width: 100%;
        max-width: var(--max-feed-width);
    }
}

.feed-item {
    margin-bottom: 20px;
    background-color: var(--color-background-darker);
    overflow: hidden;
    // max-width: var(--max-feed-width);
    border-radius: var(--border-radius);
    border: 1px solid var(--color-border);

    .status-bar {
        display: flex;
        justify-content: center;
        padding: 5px;
    }

    .feed-item-content {
        margin: 15px;
    }

    .feed-item-tags {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        margin-top: 15px;
        align-items: center;
    }

    .feed-item-media {
        padding: 15px;
    }
}

@media #{$mobileMediaQuery} {
    .feed-item {
        margin-bottom: 10px;
    }
}

.tag-button {
    color: var(--color-text-body);
    font-size: var(--font-size-small);
    display: block;
    text-align: center;
    padding: 3px 6px;
    text-decoration: none;
    border: 1px solid var(--color-border);
    border-radius: 100px; // Can be any value higher than its height
    opacity: 0.5;
    // transition: .1s opacity, .1s background-color;

    &:hover, &:focus, &:active {
        opacity: 1;
        background-color: rgba(255, 255, 255, 0.1);
        text-decoration: none;
    }
}