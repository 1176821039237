
.skButton {
    display: flex;
    border-radius: var(--border-radius);
    text-decoration: none;
    padding: 12px 20px;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 10px;

    transition: .05s background-color linear, .05s border-color linear, .05s color linear, .05s filter linear;

    background-color: 

    svg {
        width: 22px;
        height: 22px;
        flex: 0 0 auto;
    }

    &.small {
        padding: 8px 12px;
    }
    
    &:hover, &:focus {
        text-decoration: none;
    }

    &.primary {
        background-color: var(--color-primary);
        color: var(--color-text-body);

        &:not(.disabled):hover, &:not(.disabled):focus {
            background-color: var(--color-primary-hover);
            //filter: brightness(125%);
        }
    }

    &.secondary {
        background-color: rgba(255,255,255,0.1);
        color: var(--color-text);
        border: 1px solid var(--color-divider);

        &:not(.disabled):hover, &:not(.disabled):focus {
            background-color: rgba(255,255,255,0.3);
            border: 1px solid var(--color-border-selected);
        }
    }

    &.secondary-invert {
        background-color: var(--color-background-darker);
        color: var(--color-text-inverted);
    }

    &.text {
        background-color: transparent;
        color: var(--color-text-body);

        &:not(.disabled):hover, &:not(.disabled):focus {
            background-color: var(--color-background-darker);
        }
    }

    &.outlined {
        background-color: rgba(0, 0, 0, 0.20);
        color: var(--color-text-body);
        border: 1px solid var(--color-divider);

        &:not(.disabled):hover, &:not(.disabled):focus {
            background-color: var(--color-background-darker);
            border: 1px solid var(--color-border-selected);
        }
    }

    &.circle {
        width: 38px;
        height: 38px;
        aspect-ratio: 1 / 1;
        padding: 0;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.disabled {
        &:hover {
            cursor: default;
        }
    }
}
