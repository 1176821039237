
.notification-method-card {
    margin: 10px 0;

    .status-message {
        display: flex;
        align-items: center;
        margin-top: 10px;
        gap: 5px;
        font-size: var(--font-size-subtle);
    }
}