@import "../vars.scss";

.component-skImageTheater {
    width: 100%;
    height: 100%;
    display: flex;

    #image-theater-close {
        position: absolute;
        top: calc(20px + env(safe-area-inset-top));
        right: calc(20px + env(safe-area-inset-right));
        z-index: 10;
    }

    .theater-container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px;
    }

    .theater-image {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        max-width: 1024px;
        height: 100%;

        > div {
            position: absolute;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;

            height: 100%;
            width: 100%;
        }

        >img {
            opacity: 0;
        }
        flex: 1;
    }

    #image-theater-buttons {
        z-index: 4;
        height: 100%;
        padding: 15px;
        position: absolute;

        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        opacity: 0.8;
        transition: opacity .1s ease-in-out, transform .1s ease-in-out;
        transform-origin: center;

        &:hover {
            opacity: 1;
            transform: scale(1.1);
        }

        > svg {
            background-color: rgba(0, 0, 0, 0.5);
            padding: 8px;
            box-sizing: content-box;
            border-radius: 50%;
        }

        &.disabled {
            display: none;
        }
    }

    #image-theater-backward {
        @extend #image-theater-buttons;
        left: 0;
    }

    #image-theater-forward {
        @extend #image-theater-buttons;
        right: 0;
    }
}


@media #{$mobileMediaQuery} {
    .component-skImageTheater.mobile-theater {
        background-color: #000;
        display: block;

        #image-theater-backward,
        #image-theater-forward {
            display: none;
        }

        .theater-container {
            padding: 0;
            width: 400vw;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .theater-image {
            touch-action: none;
            max-width: 100vw;
            height: 100%;
        }
    }
}