@import "../vars.scss";

.artist-planet {
    background-size: cover;
    border-radius: 50%;
    cursor: pointer;
    transition: .1s transform ease-in-out, .1s box-shadow ease-in-out, .1s border-radius ease-in-out;
    position: relative;
    // border: 4px solid var(--color-background);

    width: 46px;
    height: 46px;
    outline: none;

    &.small {
        width: 32px;
        height: 32px;

        .artist-moon {
            border: 2px solid var(--color-background);
        }
    }

    &.large {
        width: 52px;
        height: 52px;

        .artist-moon {
            border: 4px solid var(--color-background);
        }
    }

    &.button:hover {
        transform: scale(1.15);
        border-radius: 25%;
    }

    &.button:active {
        transform: scale(1.15) translateY(2px);
    }
    
    &.selected {
        box-shadow: 0 0 0 3px var(--color-selected-background);
        border-radius: 25%;
        // border: 4px solid rgb(0, 133, 85);
    }

    .artist-moon {
        position: absolute;
        border: 3px solid var(--color-background);
        border-radius: 50%;
        background-color: var(--color-commissions-unknown);
        width: 50%;
        height: 50%;
        right: -12%;
        bottom: -12%;
    }

    &.open .artist-moon {
        background-color: var(--color-commissions-open);
    }

    &.closed .artist-moon {
        background-color: var(--color-commissions-closed);
    }

    &.opening-soon .artist-moon {
        background-color: var(--color-commissions-opening-soon);
    }
}

.artist-planet-list {
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 0 10px;
    margin-top: 16px;
    margin-bottom: 16px;
}

@media #{$mobileMediaQuery} {
    .artist-planet-list {
        padding: 0 8px 0 6px;
    }
    
    .artist-planet {
        // &.small {
        //     width: 32px;
        //     height: 32px;
        // }
        
        &.medium {
            width: 40px;
            height: 40px;
        }

        &.large {
            width: 44px;
            height: 44px;
        }
    }
}