@import "../vars.scss";

.component-userImage {
    border-radius: 50%;
    transition: .1s transform ease-in-out, .1s box-shadow ease-in-out, .1s border-radius ease-in-out;

    &.button:hover {
        // transform: scale(1.15);
        border-radius: 25%;
    }
}

@media #{$mobileMediaQuery} {
    .component-userImage.button:hover {
        border-radius: 50%;
    }
}