@import './vars.scss';

:root {
    color-scheme: light dark;

    // Colours
    --color-text-body: #fff;
    --color-text-subtle: #888;
    --color-text-inverted: #333;
    --color-primary: hsl(211, 100%, 50%); // #007bff;
    --color-background: #23252D;
    --color-background-mid: #1c1d22;
    --color-background-dark: #23252B;
    --color-background-darker: rgb(18, 18, 21);
    --color-border: #2c2f36; // #3c414e
    --color-divider: #3c414e;
    --color-border-selected: #777;
    --color-background-header: rgba(35, 37, 43, 0.8);

    --color-primary-hover: 	hsl(211, 100%, 35%);

    --color-selected-background: #aaaaaa;

    --color-success: #008555;
    --color-failure: #fa2020;
    --color-warning: #ffb731;
    --color-blue: #008BD9;
    --color-grey: #3C414E;

    --color-commissions-open: var(--color-success);
    --color-commissions-closed: rgb(60, 65, 78);
    --color-commissions-opening-soon: rgb(0, 139, 217);
    --color-commissions-unknown: rgb(60, 65, 78);

    --gradient-neutral: linear-gradient(51deg, rgba(199,0,217,1) 0%, rgba(0,139,217,1) 100%);
    --gradient-success: linear-gradient(121deg, rgba(0,133,85,1) 0%, rgba(0,184,75,1) 100%);
    --gradient-blue: linear-gradient(121deg, rgb(0 139 217) 0%, rgb(0 78 159) 100%);
    --gradient-inactive: linear-gradient(121deg, rgb(44 47 54) 0%, rgb(67 76 86) 100%);

    --color-left-nav: rgb(32, 32, 36);

    // Font
    --font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    --font-size-body: 1.5rem;
    --font-size-subtle: 1.3rem;
    --font-size-small: 1.2rem;

    // Sizes
    --max-feed-width: 600px;
    --border-radius: 10px;
    --header-height: 60px;
    --left-nav-width: 60px;
    --footer-height: 0px;
    --content-padding: 20px;

    // Responsive breakpoints
    --breakpoint-width-mobile: 768px;
}

* {
    box-sizing: border-box;
}

html {
    background-color: var(--color-background);
    font-size: 62.5%;
}

body {
    margin: 0;
    font-family: var(--font-family);
    font-size: var(--font-size-body);
    line-height: 1.4;
    color: var(--color-text-body);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
    color: var(--color-blue);
    text-decoration: none;

    &:hover, &:focus, &:active {
        text-decoration: underline;
    }
}

.subtle {
    font-size: var(--font-size-subtle);
    color: var(--color-text-subtle);
}

@media #{$mobileMediaQuery} {
    :root {
        // --font-size-body: 1.4rem;
        --max-feed-width: 768px;
        --header-height: 50px;
        --footer-height: 55px;
        --content-padding: 15px;
    }

    html {
        --webkit-user-select: none;
        user-select: none;
    }

    body {
        min-height: calc(100vh - var(--header-height) - var(--footer-height) - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    }
}

button {
    font-family: var(--font-family);
    font-size: var(--font-size-body);
    font-weight: 500;
    background-color: var(--color-primary);
    //border: 1px solid var(--color-primary);
    border: none;
    border-radius: var(--border-radius);
    
}

input, select, textarea {
    font-family: var(--font-family);
    font-size: var(--font-size-body);
    background-color: var(--color-background-darker);
    border: 1px solid var(--color-border);
    color: var(--color-text-body);
    padding: 12px;
    border-radius: var(--border-radius);
    transition: .05s border linear;
    outline: none;
    min-width: 220px;

    &:not(:disabled):hover {
        border: 1px solid var(--color-border-selected);
    }

    &:not(:disabled):focus {
        border: 1px solid var(--color-primary);
    }

    &::placeholder {
        color: var(--color-text-subtle);
    }

    &:disabled {
        background-color: var(--color-background-darker);
        color: var(--color-text-subtle);
        opacity: 0.8;
    }
}

.form-group {
    margin: 30px 0;
}

label {
    color: var(--color-text-body);
    font-weight: bold;
    margin-bottom: 5px;
}

form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

p {
    margin: 10px 0;
}

h1 {
    font-size: 3.2rem;
    font-weight: bold;
    margin: 50px 0 20px 0;

    &:first-child {
        margin-top: 0;
    }
}

h2 {
    font-size: 2.6rem;
    font-weight: bold;
    margin: 50px 0 20px 0;

    &:first-child {
        margin-top: 0;
    }
}

h3 {
    font-size: 2rem;
    font-weight: bold;
    margin: 50px 0 20px 0;

    &:first-child {
        margin-top: 0;
    }
}

h4 {
    font-size: var(--font-size-body);
    font-weight: bold;
    margin: 40px 0 10px 0;
}

hr {
    border: 0;
    border-top: 1px solid var(--color-divider);
    margin: 20px 0;
    width: 100%;
}

h1+hr, h2+hr, h3+hr, h4+hr, h5+hr, h6+hr {
    margin-top: 0;
}

h1:has(+hr), h2:has(+hr), h3:has(+hr) {
    margin-bottom: 10px;
}

hr+p {
    margin-top: 0;
}

@media #{$mobileMediaQuery} {
    h1:first-child {
        margin-top: 10px;
    }
}

.scrollable, html {
    scrollbar-color: var(--color-border) var(--color-left-nav);

    &::-webkit-scrollbar {
        // display: none;
        background-color: var(--color-left-nav);
        width: 18px;
        height: 18px;
    }

    &::-webkit-scrollbar-corner {
        background-color: var(--color-left-nav);
    }

    &::-webkit-scrollbar-track {
        background-color: var(--color-left-nav);
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--color-border);
        border-radius: 10px;
        border: 5px solid transparent;
        background-clip: padding-box;
    }
}

.scrollable {
    scrollbar-width: thin;
}

@supports (font: -apple-system-body) {
    :root {
        font: -apple-system-body !important;
        --font-size-body: 0.90rem;
        --font-size-subtle: 0.80rem;
        --font-size-small: 0.80rem;
    }

    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.625rem;
    }

    h3 {
        font-size: 1.25rem;
    }
}