
.component-skFilters {

    .skFilters-schemaControls {
        display: flex;
        flex-direction: row;
        gap: 5px;
        overflow-x: auto;

        margin-bottom: 20px;
        max-width: 100%;
    }

    .skFilters-filter {
        h4 {
            margin-top: 0;
            margin-bottom: 10px;
        }

        hr {
            margin: 10px 0;
        }
    }
}