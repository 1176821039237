@import "../vars.scss";

.nav-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: calc(env(safe-area-inset-bottom) + var(--footer-height));
    z-index: 100;
    // background-color: var(--color-background-mid);
    background-color: rgba(28,29,34,0.9);
    backdrop-filter: blur(5px);
    border-top: 1px solid var(--color-border);
    padding-bottom: env(safe-area-inset-bottom);

    display: none;
    flex-direction: row;
    align-items: center;

    transition: .3s opacity linear, .3s backdrop-filter linear;

    .nav-button {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        background-color: transparent;
        border: none;

        transition: .2s background-color;

        &:active {
            background-color: var(--color-background-darker);
        }

        // transition: .5s opacity;

        // &:active {
        //     opacity: 0.1;
        // }
    }
}

body.scrolled .nav-footer {
    opacity: 0.5;
    backdrop-filter: none;
}

@media #{$mobileMediaQuery} {
    .nav-footer {
        display: flex;
    }
}