
.component-skTextInput {
    position: relative;

    input {
        width: 100%;
    }

    .input-reset {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 45px;
        opacity: 0.25;
        transition: .05s opacity linear;
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            opacity: 1;
        }
    }
}