
.artist-tile {
    padding: 10px;
    background-color: var(--color-background-darker);
    padding: 10px;
    border-radius: var(--border-radius);

    display: flex;
    gap: 15px;

    &:hover {
        background-color: var(--color-background-dark);
        text-decoration: none;
    }

    &.selected {
        background-color: var(--color-blue);

        .subtle {
            color: var(--color-text-body);
        }
    }

    color: var(--color-text-body);
}