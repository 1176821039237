@import "../vars.scss";

.profile-screen {

    .profile-info {
        display: flex;
        flex-direction: column;
        gap: 20px;

        h2 {
            margin: 0;
        }

        .profile-name {
            flex: 1;
            display: flex;
            gap: 20px;
        }
    }

    main {
        max-width: 800px
    }

    .admin-controls {
        display: flex;
        gap: 10px;
        margin-bottom: 10px;
        flex-wrap: wrap;
    }
}

@media #{$mobileMediaQuery} {
    .profile-screen {
        padding: var(--content-padding);

        .profile-info {
            gap: 20px;

            .profile-name {
                gap: 10px;
            }

            .profile-buttons {

            }
        }
    }
}