@import "../vars.scss";

.screen-wrapper {
    width: 100%;
    margin-top: calc(env(safe-area-inset-top) + var(--header-height));
    margin-bottom: calc(env(safe-area-inset-bottom) + var(--footer-height));

    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;

    display: flex;

    // display: grid;
    // grid-template-columns: 1fr;

    &.has-left {
        grid-template-columns: auto 1fr;
    }

    .left {
        position: fixed;
        top: var(--header-height);
        left: env(safe-area-inset-left);
        min-height: calc(100vh - var(--header-height) - var(--footer-height) - env(safe-area-inset-top) - env(safe-area-inset-bottom));
        height: 0;
        overflow-y: auto;
        // Leg was seeing horizontal scrollbar when hovering 
        overflow-x: hidden; 
        background-color: var(--color-left-nav);

        // Support for mobile safe screen areas
        padding-left: env(safe-area-inset-left);
    }

    // .left {
    //     position: fixed;
        
    //     min-height: calc(100vh - var(--header-height) - var(--footer-height) - env(safe-area-inset-top) - env(safe-area-inset-bottom));
    //     overflow-y: auto;
    //     // Leg was seeing horizontal scrollbar when hovering 
    //     overflow-x: hidden; 
    //     background-color: var(--color-left-nav);

    //     // Support for mobile safe screen areas
    //     padding-left: env(safe-area-inset-left);

    //     width: 70px;
    //     top: var(--header-height);
    //     left: 0;
    //     bottom: var(--footer-height);

    //     transition: .1s top linear;
    // }

    main {
        // padding: var(--content-padding);
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        padding: 20px;

        // &:first-child {
        //     padding-left: max(var(--content-padding), env(safe-area-inset-left));
        // }
    }
}

@media #{$mobileMediaQuery} {
    .screen-wrapper {

        main {
            padding: 0;
        }

        &.has-left {
            padding-left: calc(env(safe-area-inset-left) + var(--left-nav-width));
        }

        // main {
        //     padding: 0;

        //     &:first-child {
        //         padding-left: env(safe-area-inset-left);
        //     }
        // }

        // &.padded {
        //     padding: 10px;
        // }

        .left {
            top: calc(env(safe-area-inset-top) + var(--header-height));
        }
    }
}