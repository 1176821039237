
.component-card {
    border-radius: 10px;
    background-color: #121215;
    padding: 20px;

    &.button {
        cursor: pointer;
    }
}
