@import "../vars.scss";

.image-grid {
    display: flex;
    width: 100%;
    position: relative;
    border-radius: var(--border-radius);
    overflow: hidden;

    display: grid;
    gap: 4px;

    .grid-blur {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(25px);
        padding: 20px;
        border-radius: var(--border-radius);
        overflow: hidden;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        p {
            text-shadow: 0 3px 6px rgba(0,0,0,0.2), 0 3px 6px rgba(0,0,0,0.27);
        }

        .blur-button-mobile { display: none; }
        .blur-button-desktop { display: block; }
    }

    .grid-hide {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 20px;

        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;

        button {
            min-width: 100px;
        }
    }
}

// .image-grid-1 {
//     .grid-image {
//         border-radius: var(--border-radius);
//     }
// }

.image-grid-2 {
    grid-template-columns: 1fr 1fr;
    
    .grid-image {
        aspect-ratio: 1 / 1;
    }

    // .grid-image:nth-child(1) { border-radius: var(--border-radius) 0 0 var(--border-radius); }
    // .grid-image:nth-child(2) { border-radius: 0 var(--border-radius) var(--border-radius) 0; }
}

.image-grid-3 {
    display: flex;
    gap: 4px;

    .left-grid {
        flex: 1;
        aspect-ratio: 1 / 1;
    }

    .right-grid {
        flex: 1;
        aspect-ratio: 1 / 1;
        display: flex;
        flex-direction: column;
        gap: 4px;

        .grid-image {
            flex: 1;
        }
    }
}

.image-grid-4 {
    grid-template-columns: 1fr 1fr;

    // .grid-image:nth-child(1) { border-radius: var(--border-radius) 0 0 0; }
    // .grid-image:nth-child(2) { border-radius: 0 var(--border-radius) 0 0; }
    // .grid-image:nth-child(3) { border-radius: 0 0 0 var(--border-radius); }
    // .grid-image:nth-child(4) { border-radius: 0 0 var(--border-radius) 0; }
}

.grid-image {
    cursor: pointer;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: left 50% top 50%;
    }
}

@media #{$mobileMediaQuery} {
    .image-grid {
        .grid-blur .blur-button-mobile { display: block; }
        .grid-blur .blur-button-desktop { display: none; }
    }
}