
.component-searchHitContainer {

    padding: 10px;
    
    display: flex;
    flex-direction: column;
    gap: 5px;

    &.floating {
        border: 1px solid var(--color-border);
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        background-color: var(--color-left-nav);

        border-radius: var(--border-radius);
        position: fixed;
        right: 77px;
        top: calc(var(--header-height) + 10px);
        width: 380px;

        max-height: calc(100vh - var(--header-height) - var(--footer-height) - 20px);
        overflow-y: auto;
    }

    &.fullscreen {
        width: 100%;
    }

    .search-hit-header {
        font-weight: bold;
        margin-bottom: 5px;

        &:not(:first-child) {
            border-top: 1px solid var(--color-border);
            padding-top: 8px;
            margin-top: 8px;
        }
    }
}