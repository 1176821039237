@import "../vars.scss";

// Be sure to update setTimeout value in SkModal.tsx
$transitionTime: 0.2s;

.component-skModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    overflow: visible;
    z-index: 1000;

    .dialog-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.7);
        backdrop-filter: blur(5px);
        opacity: 0;
        transition: $transitionTime opacity ease-out;

        touch-action: none;

        &.open {
            opacity: 1;
        }
    }

    .dialog-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateY(3%);
        opacity: 0;
        transition: $transitionTime transform ease-out, $transitionTime opacity ease-out;

        touch-action: none;

        &.open {
            transform: translateY(0);
            opacity: 1;
        }
    }

    .modal-dialog {
        width: 600px;
        max-width: calc(100vw - 40px);
        padding: 20px;
        background-color: var(--color-background-dark);
        border-radius: var(--border-radius);
        border: 1px solid var(--color-divider);
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        display: flex;
        flex-direction: column;
        gap: 20px;

        &.no-padding {
            padding: 0;
        }

        .dialog-title {
            margin: 0;
            flex: 1;
        }
    }

    .modal-title {
        display: flex;
        align-items: center;
        flex: 0 0 auto;
    }

    .modal-actions {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 20px;
    }

}

@media #{$mobileMediaQuery} {
    .component-skModal {
        .modal-dialog {
            
            &.center {
                align-self: center;
            }
            &.bottom {
                align-self: flex-end;
                margin-bottom: calc(env(safe-area-inset-bottom) + 10px);
            }
            &.top {
                align-self: flex-start;
            }
            // align-items: flex-end;
            
        }

        .modal-actions {
            flex-direction: column-reverse;
        }
    }
}