
.search-screen {
    // padding: var(--content-padding);

    .mobile-search-box {
        position: fixed;
        width: 100%;
        background-color: var(--color-background-header);
        backdrop-filter: blur(5px);
        z-index: 1;
        padding: var(--content-padding);
    }

    .mobile-search-hits {
        padding-top: 75px;
    }

    .mobile-search-recents {
        padding-top: 75px;
    }
}