@import "../vars.scss";

.page-home {
    .selected-artist-feed {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        width: 100%;
        max-width: var(--max-feed-width);
        margin: 0 auto;
    }

    .artist-profile-card {
        padding: 20px;
        border-radius: 10px;
    }
}

@media #{$mobileMediaQuery} {
    .page-home {
        main {
            padding: 10px;
        }

        .artist-profile-card {
            padding: 10px;
        }
    }
}