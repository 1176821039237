
.artist-preview-card {
    color: var(--color-text-body);
    display: flex;
    gap: 20px;
    padding: 10px;
    border-radius: var(--border-radius);
    background-color: var(--color-background-darker);

    &:hover:not(.follow-only) {
        text-decoration: none;
        background-color: var(--color-background-hovered);
    }

    .time-since-open {
        display: flex;
        position: absolute;
        width: 20px;
        height: 20px;
    }

    .artist-info {
        flex: 1;
    }
}
