
.component-skPill {
    padding: 8px 16px;
    background-color: var(--color-background-darker);
    border-radius: 100px; // Can be any value higher than its height
    align-items: center;
    font-size: var(--font-size-subtle);

    &:hover {
        background-color: var(--color-background-dark);
    }

    &.selected {
        background-color: var(--color-blue);
    }
}