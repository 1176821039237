.welcome-modal {
    display: flex;
    align-items: stretch;
    // padding: 0 20px;
    gap: 20px;

    .welcome-title {
        text-align: center;
        h1 { margin: 0 0 20px 0; }
    }

    .welcome-buttons {
        display: flex;
        flex-direction: column;
        gap: 20px;

        button {
            padding: 20px;
        }
    }

    .welcome-artists {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    p a,
    span a {
        color: var(--color-text-body);
        text-decoration: underline;
        font-weight: bold;
        display: inline-flex;
        align-items: center;
    }

    a > svg {
        margin-left: 0.2em;
    }

    h3 {
        margin-top: 0;
        margin-bottom: 10px;
    }
}