@import "../vars.scss";

.followed-screen {
    
    .follow-list {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 15px;
    }
}

@media #{$tabletMediaQuery} {
    .followed-screen {
        .follow-list {
            grid-template-columns: 1fr 1fr;
        }
    }
}

@media #{$mobileMediaQuery} {
    .followed-screen {
        padding: var(--content-padding);

        // h1 {
        //     margin-top: 10px;
        // }

        .follow-list {
            display: grid;
            grid-template-columns: 1fr;
            gap: 10px;
        }
    }
}