@import "../vars.scss";

.desktop-header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: var(--color-background-header);
    z-index: 1;
    backdrop-filter: blur(5px);
    border-bottom: 1px solid var(--color-border);

    // Support for mobile safe screen areas
    padding-left: env(safe-area-inset-left);

    display: flex;
    justify-content: space-between;
    gap: 40px;
    align-items: center;
    width: 100%;
    height: var(--header-height);

    .header-left {
        display: flex;
        align-items: center;
        padding-left: 20px;
        flex-direction: row;
        gap: 20px;
    }

    .header-right {
        display: flex;
        height: 100%;
        align-items: center;
        padding-right: 20px;
        flex-direction: row;
        gap: 20px;

        flex: 1;
        justify-content: flex-end;

        .component-skTextInput {
            width: 100%;
            max-width: 320px;
        }
    }

    .header-button {
        opacity: 0.5;
        background-color: transparent;

        &.active, &:focus {
            opacity: 1;
            background-color: transparent !important;
        }

        &:hover {
            opacity: 0.8;
            background-color: transparent !important;
        }
    }
}

.mobile-header {
    display: none;
    position: fixed;
    width: 100%;
    top: 0;
    background-color: rgba(35, 37, 43, 0.8);
    padding-top: env(safe-area-inset-top);
    z-index: 1;
    backdrop-filter: blur(5px);
    height: calc(env(safe-area-inset-top) + var(--header-height));

    grid-template-columns: 50px 1fr 50px;
    border-bottom: 1px solid var(--color-border);

    // Support for mobile safe screen areas
    padding-left: env(safe-area-inset-left);

    // transition: .25s top ease-out;
    transition: .1s top linear;
}

// body.scrolled .mobile-header {
//     top: -60px;
//     height: 60px;
// }

// body.scrolled {
//     --header-height: 0;
// }

.mobile-back {
    width: 50px;
    display: flex; 
    align-items: center;
    justify-content: center;
}

@media #{$mobileMediaQuery} {
    .desktop-header {
        display: none;
    }
    .mobile-header {
        display: grid;
    }
}